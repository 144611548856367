$wedding-app-navy: #030044;
$wedding-app-navy-dark: #020b2d;
$wedding-app-ruby: #751f44;
$wedding-app-blush: #FFE9FC;

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Carrois+Gothic+SC&display=swap');

h1 {
    font:400 24px/32px 'Arial Narrow',Helvetica,sans-serif;
    margin: 0 0 16px;
}

h2 {
  font: 500 20px/32px 'Arial Narrow',Helvetica,sans-serif;
  margin: 0 0 16px;
}

a {
    cursor: pointer;

    &.ruby, .ruby {
        &:hover:not(.mat-button) {
            color: $wedding-app-navy;
        }
    }

    &.navy, .navy {
        &:hover:not(.mat-button) {
            color: $wedding-app-ruby;
        }
    }
}

.navy, a.navy {
    color: $wedding-app-navy;
}

.ruby, a.ruby {
    color: $wedding-app-ruby;
}

.blush, a.blush {
    color: $wedding-app-blush;
}

.menu-spacer {
    height: 56px;
}

.section {
    .content-wrapper {
        .content {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
        .center {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    a.mat-button {
        font-size: 1.5rem;
    }

    .more-info {
        font-family: 'Dancing Script';
        font-weight: bold;
        font-size: 2.5rem;
        line-height: 2.5rem;
    }

    .title {
        font-size: 3rem;
        font-family: 'Carrois Gothic SC';
        text-align: center;
        margin-bottom: 2rem;
        color: $wedding-app-navy;
    }

    &.bg-navy {
        background-color: $wedding-app-navy-dark;
        color: whitesmoke;

        .title, .sub-title {
            color: whitesmoke;
        }

        a.mat-button {
            background-color: $wedding-app-ruby;
        }
    }

    &.bg-ruby {
        background-color: $wedding-app-ruby;
        color: whitesmoke;

        .title {
            color: whitesmoke;
        }

        .mat-button {
            &:hover:not(:active) {
                color: #fff;
                background-color: $wedding-app-navy;
            }
        }
    }

    &:not(.bg-navy):not(.bg-ruby) {
        .mat-button {
            color: #fff;
            background-color: $wedding-app-navy;

            &:hover:not(:active) {
                color: $wedding-app-navy;
                background-color: #fff;
            }
        }
    }
}

@media only screen and (max-device-width : 1023px) {
    .section .content-wrapper .content {
        flex-direction: column;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;

        .info-container {
            flex-direction: column;
            justify-content: center;
            margin-top: 0;
        }
    }
}
@media only screen and (max-device-width: 667px) and (orientation : portrait) {
    .mat-toolbar {
        .text {
            display: none;
        }
        .mat-icon {
            display: inline-block;
        }
    }
}

.section-home {
  background-image: url('/david-and-emily-bg.jpg');
  background-size: cover;
  background-position: bottom center;

  .arrow {
    border: none;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: 0;
    transform: translate3d(0,0,0);
    transition: background .4s cubic-bezier(.25,.8,.25,1),box-shadow 280ms cubic-bezier(.4,0,.2,1);
    min-width: 0;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    padding: 0;
    flex-shrink: 0;

    background-color: #030044;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 29px);

    box-shadow: 0 3px 5px -1px rgba(0,0,0,.2), 0 6px 10px 0 rgba(0,0,0,.14), 0 1px 18px 0 rgba(0,0,0,.12);

    svg {
      fill: white;
      height: 24px;
      width: 100%;
    }
  }

  .content.home {
      height: calc(100vh - 56px);

      .title {
          color: $wedding-app-navy;
      }

      .date {
          font-family: 'Dancing Script';
          font-weight: bold;
          font-size: 2rem;
          color: $wedding-app-ruby;
      }
  }
}

@media only screen and (min-device-width : 1024px) {
  .section-home {
    background-image: url('/david-and-emily-bg-lg.jpg');
  }
}

@media only screen and (max-device-width : 1023px) and (orientation: portrait) {
  .section-home {
    .content.home {
      height: calc(100vh - calc(100vw * 1.071428571428571));
      flex: none;

      .title {
          font-size: 2.5rem;
      }
    }
  }
}

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (orientation : portrait) {
  .section-home {
    .content.home {
      height: calc(calc(100vh - calc(100vw * 1.071428571428571)) - 76px);

      .title {
          font-size: 2.5rem;
          margin-bottom: 1rem;
      }
    }
  }
}

.section-photos {

  .gallery {
    overflow-y: auto;
    height: 100vh;
  }

  .masonry-wrapper {
    margin: 76px auto;
    max-width: 832px;
  }

  .masonry {
    display: flex;
    flex-flow: column wrap;
    max-height: 8900px;
    counter-reset: brick;
    width: 100%;
  }

  .masonry-brick {
    margin: 0 8px 8px 0;
    min-width: 200px;
  }

  .masonry-img {
    max-height: 300px;
    max-width: 200px;
    cursor: pointer;
  }
}
